<template>
  <div class="scroll_wrap sytle2" ref="scrollWrap">
    <div class="slider_list"
      :style="listStyle">
      <template v-for="(item) in list">
        <!--
          @slot 슬라이드 그룹내 데이터 1개에 대한 UI
            @binding item 데이터
        -->
        <slot name="default" :item="item"></slot>
      </template>
    </div>
  </div>
</template>

<script>
import { mixin as mixinHelperUtils } from '@/common/helperUtils';

export default {
  name: 'ScrolledSlider',
  mixins: [mixinHelperUtils],
  props: {
    /**
     * 슬라이드 대상 데이터 리스트
     */
    list: {
      type: Array,
    },
    /**
     * 슬라이드 그룹당 데이터 수
     */
    itemsPerRows: {
      type: Number,
      default: 1,
    },
    itemWidth: {
      type: Number,
      default: 100,
    },
    posX: {
      type: Number,
      default: 0,
    },
  },
  computed: {
    listStyle() {
      const sliderWidth = (this.itemWidth * Math.ceil((this.list.length / this.itemsPerRows))) * 0.1;
      return {
        width: `${sliderWidth}rem`,
      };
    },
  },
  updated() {
    this.$nextTick(() => {
      this.scrollMove();
    });
  },
  methods: {
    scrollMove() {
      this.$refs.scrollWrap.scrollLeft = this.posX;
    },
  },
};
</script>
<style scoped>
</style>
