<template>
  <!-- eslint-disable -->
  <main id="content" role="main" class="studio">
    <section class="visual_intro">
        <div class="visual_wall">
        </div>
        <div class="intro_title">
            <strong class="title">OPEN INNOVATION HUB</strong>
            <strong class="title">DREAMPLUS CENTER</strong>
        </div>
    </section>

    <section class="report_group">
        <!-- INNOVATION CENTERS -->

        <section class="report_box">
            <div class="box_title">
                <h2 class="title">INNOVATION CENTERS</h2>
                <p class="description" v-html="strReplace($t('content.studio.common.innovation_centers_desc'),'<br />')">
                </p>
            </div>
        </section>
        <section class="report_specialbox" data-aos="fade-up">
             <div class="global_center_area">
                <ul class="global_center_list">
                    <li class="center_item">
                        <p class="info">FINTECH STARTUP<br>INCUBATOR</p>
                        <p class="name" v-html="$t('content.studio.innovation_centers.center_63')"></p>
                        <div class="oval"></div>
                        <div class="ovalline"></div>
                    </li>
                    <li class="center_item">
                        <p class="info">OPEN INNOVATION<br>CENTER & ACCELERATOR</p>
                        <p class="name" v-html="$t('content.studio.innovation_centers.center_gangnam')"></p>
                        <div class="oval"></div>
                        <div class="ovalline"></div>
                    </li>
                    <li class="center_item">
                        <p class="info">GLOBAL BRANCH<br>(JAPAN)</p>
                        <p class="name" v-html="$t('content.studio.innovation_centers.center_fukuoka')"></p>
                        <div class="oval"></div>
                        <div class="ovalline"></div>
                    </li>
                    <li class="center_item">
                        <p class="info">GLOBAL BRANCH<br>(USA)</p>
                        <p class="name" v-html="$t('content.studio.innovation_centers.center_sanfrancisco')"></p>
                        <div class="oval"></div>
                        <div class="ovalline"></div>
                    </li>
                    <li class="center_item">
                        <p class="info">GLOBAL BRANCH<br>(CHINA)</p>
                        <p class="name" v-html="$t('content.studio.innovation_centers.center_beijing')"></p>
                        <div class="oval"></div>
                        <div class="ovalline"></div>
                        <div class="ovalarrow"></div>
                    </li>
                </ul>
            </div>
        </section>

        <!-- // INNOVATION CENTERS -->

        <!-- DREAMPLUS 63 -->
        <section class="report_box">
            <div class="box_title">
                <h2 class="title">DREAMPLUS 63</h2>
                <p class="description" v-html="strReplace($t('content.studio.common.dreamplus_63_desc'),'<br />')">
                </p>
            </div>
            <div data-aos="fade-up">
              <img :src="requireAssetsMobileImage('studio/img_dp63.png')" class="img_center">
            </div>
        </section>
        <!-- // DREAMPLUS 63 -->
        <section class="report_box">
          <div class="box_subtitle">
            <h2 class="subtitle">MEMBERS</h2>
          </div>
          <div class="partner_swiper scroll_slider">
              <ScrolledSlider
                :list="MobileStudioMember.SwiperList[0]"
                :itemWidth="100"
                :itemsPerRows="4"
              >
             <template v-slot="{ item }">
                <div class="slider" :class="{ 'hide': item.img ===''} ">
                  <div class="card-wrap">
                    <div class="card">
                      <img v-if="item.img" :src="requireAssetsImg(`${item.img}`)"
                            :alt="item.title">
                      <!-- <span :class="item.img ? 'blind': 'card-logo-text'">{{item.title}}</span> -->
                    </div>
                  </div>
                </div>
              </template>
              </ScrolledSlider>
          </div>
          <div class="shortcut_area">
              <a href="javascript:void(0)" role="button" class="btn_shortcut" @click="sixThreeGo"
                v-html="$t('content.studio.common.go_63')">
              </a>
          </div>
        </section>
        <!-- DREAMPLUS GANGNAM -->
        <section class="report_box">
            <div class="box_title">
                <h2 class="title"><p>DREAMPLUS</p><p>GANGNAM</p></h2>
                <p class="description" v-html="strReplace($t('content.studio.common.dreamplus_gangnam_desc'),'<br />')">
                </p>
            </div>
            <div data-aos="fade-up">
              <img :src="requireAssetsMobileImage('studio/img_dpgn.png')" class="img_center">
            </div>
        </section>
        <!-- // DREAMPLUS GANGNAM -->
        <section class="report_box">
          <div class="box_subtitle">
            <h2 class="subtitle">MEMBERS</h2>
          </div>
          <div class="partner_swiper scroll_slider">
              <ScrolledSlider
                :list="MobileStudioMember.SwiperList[1]"
                :itemWidth="100"
                :itemsPerRows="4"
              >
              <template v-slot="{ item }">
                <div class="slider" :class="{ 'hide': item.img ===''} ">
                  <div class="card-wrap">
                    <div class="card">
                      <img v-if="item.img" :src="requireAssetsImg(`${item.img}`)"
                            :alt="item.title">
                      <!-- <span :class="item.img ? 'blind': 'card-logo-text'">{{item.title}}</span> -->
                    </div>
                  </div>
                </div>
              </template>
              </ScrolledSlider>
          </div>
          <div class="shortcut_area">
              <a href="javascript:void(0)" role="button" class="btn_shortcut" @click="ganGnamGo"
                v-html="$t('content.studio.common.go_gangnam')">
              </a>
          </div>
        </section>
        <!-- DREAMPLUS GLOBAL -->
        <section class="report_box">
            <div class="box_title">
                <h2 class="title"><p>DREAMPLUS</p><p>GLOBAL</p></h2>
                <p class="description" v-html="strReplace($t('content.studio.common.dreamplus_global_desc'),'<br />')">
                </p>
            </div>
            <div class="overseas_center_list">
                <div class="overseas_center" data-aos="fade-up">
                    <img :src="requireAssetsMobileImage('studio/img_sanf.png')" class="img_center">
                    <strong class="name">SAN FRANCISCO</strong>
                    <p class="description" v-html="$t('content.studio.global_centers.senfrancisco')">
                    </p>
                </div>
                <div class="overseas_center" data-aos="fade-up">
                    <img :src="requireAssetsMobileImage('studio/img_fukuoka.png')" class="img_center">
                    <strong class="name">FUKUOKA</strong>
                    <p class="description" v-html="$t('content.studio.global_centers.fukuoka')">
                    </p>
                </div>
                <div class="overseas_center" data-aos="fade-up">
                    <img :src="requireAssetsMobileImage('studio/img_beijing.png')" class="img_center">
                    <strong class="name">BEIJING</strong>
                    <p class="description" v-html="$t('content.studio.global_centers.beijing')">
                    </p>
                </div>
            </div>
            <div class="shortcut_area">
                <router-link :to="{ name: 'startup' }" class="btn_shortcut"
                v-html="$t('content.studio.common.go_k_startup')">
                </router-link>
            </div>
        </section>
        <!-- // DREAMPLUS GLOBAL -->

    </section>
</main>

</template>

<script>
import { mixin as mixinHelperUtils } from '@/common/helperUtils';
// import { Swiper, SwiperSlide } from 'vue-awesome-swiper';
import MobileStudioMember from '@/views/dreamplus/MobileStudioMember';
// import StaggerTransition from '@/components/transition/StaggerTransition.vue';
import '@/assets/m_css/studio.css';
import ScrolledSlider from '@/components/slider/ScrolledSlider.vue';

export default {
  name: 'Studio',
  mixins: [
    mixinHelperUtils,
  ],
  components: {
    ScrolledSlider,
    // Swiper,
    // SwiperSlide,
    // StaggerTransition,
  },
  mounted() {
    const elVisual = document.getElementsByClassName('visual_intro');
    setTimeout(() => {
      elVisual[0].className += ' ani_intro';
    }, 100);
  },
  data() {
    return {
      MobileStudioMember,
      mainbgImg: 'studio/img_dreamplus_center.png',
      member_swiper: {
        slidesPerView: 1,
        loop: false,
        pagination: {
          el: '.swiper-pagination-fraction',
          type: 'fraction',
        },
        navigation: {
          nextEl: '#memberNext',
          prevEl: '#memberPrev',
        },
      },
      gangnam_swiper: {
        slidesPerView: 1,
        loop: false,
        pagination: {
          el: '.swiper-pagination-fraction1',
          type: 'fraction',
        },
        navigation: {
          nextEl: '#gangnamNext',
          prevEl: '#gangnamPrev',
        },
      },
    };
  },
  methods: {
    ganGnamGo() {
      window.open(`${process.env.VUE_APP_PORTAL}`);
    },
    sixThreeGo() {
      window.open(`${process.env.VUE_APP_63}`);
    },
  },
};
</script>
